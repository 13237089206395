var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.client && _vm.portfolio && _vm.instrument)?_c('div',{staticClass:"prtf-page"},[_c('div',{staticClass:"prtf-page-header"},[_c('div',{staticClass:"prtf-page-header-title-holder"},[_vm._m(0),_c('div',{staticClass:"prtf-page-header-subtitle"},[_c('InstrumentHeaderLink',{attrs:{"instrument":_vm.instrument,"position_type":_vm.pt}}),_c('ClientHeaderLink',{attrs:{"client":_vm.client}}),_c('PortfolioHeaderLink',{attrs:{"portfolio":_vm.portfolio,"clientid":_vm.client.id}})],1)]),_c('div',{staticClass:"prtf-page-header-buttons"},[_c('div',{staticClass:"prtf-page-header-button"},[_c('el-button',{attrs:{"type":"text"},on:{"click":_vm.get_list}},[_c('svg-icon',{attrs:{"icon-class":"refresh"}})],1)],1),_c('div',{staticClass:"prtf-page-header-button"},[_c('el-select',{on:{"change":_vm.change_mode},model:{value:(_vm.portfolio_mode),callback:function ($$v) {_vm.portfolio_mode=$$v},expression:"portfolio_mode"}},[_c('el-option',{attrs:{"label":"By value date","value":"value"}}),_c('el-option',{attrs:{"label":"By trade date","value":"trade"}})],1)],1)])]),_c('div',{staticClass:"prtf-page-body"},[_c('vxe-grid',{directives:[{name:"loading",rawName:"v-loading",value:(_vm.loading),expression:"loading"}],staticClass:"common-grid",attrs:{"data":_vm.list,"columns":_vm.columns,"size":"mini","auto-resize":"","max-height":_vm.table_height,"border":"","header-cell-class-name":_vm.header_cell_class_name,"cell-class-name":_vm.cell_class_name},scopedSlots:_vm._u([{key:"col_number",fn:function(ref){
var row = ref.row;
var column = ref.column;
return [_c('FormattedNumber',{attrs:{"value":row[column.property],"decimals":column.params.format.decimals,"percents":column.params.format.percents,"zeros":column.params.format.zeros,"sign":column.params.format.sign,"color":column.params.format.color}})]}},{key:"col_pnl",fn:function(ref){
var row = ref.row;
var column = ref.column;
return [(row.closed)?_c('FormattedNumber',{attrs:{"value":row[column.property],"decimals":column.params.format.decimals,"percents":column.params.format.percents,"zeros":column.params.format.zeros,"sign":column.params.format.sign,"color":column.params.format.color}}):_vm._e()]}},{key:"col_d_oper",fn:function(ref){
var row = ref.row;
var column = ref.column;
return [_c('TransactionText',{attrs:{"operation":row['debet_opers'],"container_cur":_vm.portfolio.currency,"flag":{'flag': row.d_f, 'flag_instrument': row.d_fi, 'flag_instrument_pt': row.d_fi_pt }}})]}},{key:"col_c_oper",fn:function(ref){
var row = ref.row;
var column = ref.column;
return [_c('TransactionText',{attrs:{"operation":row['credit_opers'],"container_cur":_vm.portfolio.currency,"flag":{'flag': row.c_f, 'flag_instrument': row.c_fi, 'flag_instrument_pt': row.c_fi_pt }}})]}}],null,false,2717496942)})],1)]):_vm._e()}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"prtf-page-header-maintitle"},[_c('div',{staticClass:"prtf-page-header-name"},[_vm._v("FIFO Accounting Calculations")])])}]

export { render, staticRenderFns }