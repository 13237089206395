<template>
    <div class="prtf-page" v-if="client && portfolio && instrument">
        <div class="prtf-page-header">
            <div class="prtf-page-header-title-holder">
                <div class="prtf-page-header-maintitle">
                    <div class="prtf-page-header-name">FIFO Accounting Calculations</div>
                </div>
                <div class="prtf-page-header-subtitle">
                    <InstrumentHeaderLink :instrument="instrument" :position_type="pt" />
                    <ClientHeaderLink :client="client" />
                    <PortfolioHeaderLink :portfolio="portfolio" :clientid="client.id" />
                </div>
            </div>

            <div class="prtf-page-header-buttons">
                <div class="prtf-page-header-button">
                    <el-button type="text" @click="get_list"><svg-icon icon-class="refresh" /></el-button>
                </div>
                <div class="prtf-page-header-button">
                    <el-select v-model="portfolio_mode" @change="change_mode">
                        <el-option label="By value date" value="value"></el-option>
                        <el-option label="By trade date" value="trade"></el-option>
                    </el-select>
                </div>
            </div>
        </div>
        
        <div class="prtf-page-body">
            <vxe-grid 
                :data="list" 
                :columns="columns"
                size="mini"
                auto-resize 
                :max-height="table_height"
                class="common-grid" 
                v-loading="loading"
                border
                :header-cell-class-name="header_cell_class_name"
                :cell-class-name="cell_class_name">
                
                <template v-slot:col_number="{ row, column }">
                    <FormattedNumber
                        :value="row[column.property]"
                        :decimals="column.params.format.decimals"
                        :percents="column.params.format.percents"
                        :zeros="column.params.format.zeros"
                        :sign="column.params.format.sign"
                        :color="column.params.format.color"
                         />
                </template>

                <template v-slot:col_pnl="{ row, column }">
                    <FormattedNumber
                        v-if="row.closed"
                        :value="row[column.property]"
                        :decimals="column.params.format.decimals"
                        :percents="column.params.format.percents"
                        :zeros="column.params.format.zeros"
                        :sign="column.params.format.sign"
                        :color="column.params.format.color"
                         />
                </template>

                <template v-slot:col_d_oper="{ row, column }">
                    <TransactionText 
                        :operation="row['debet_opers']" 
                        :container_cur="portfolio.currency"
                        :flag="{'flag': row.d_f, 'flag_instrument': row.d_fi, 'flag_instrument_pt': row.d_fi_pt }" />
                </template>

                <template v-slot:col_c_oper="{ row, column }">
                    <TransactionText 
                        :operation="row['credit_opers']" 
                        :container_cur="portfolio.currency"
                        :flag="{'flag': row.c_f, 'flag_instrument': row.c_fi, 'flag_instrument_pt': row.c_fi_pt }" />
                </template>

            </vxe-grid>
        </div>
    </div>
</template>

<script>
import $ from 'jquery'
import FormattedNumber from '@/components/Formats/FormattedNumber'
import FormattedDate from '@/components/Formats/FormattedDate'
import TransactionText from '@/components/PageComponents/TransactionText'
import ClientHeaderLink from '@/components/PageComponents/ClientHeaderLink'
import InstrumentHeaderLink from '@/components/PageComponents/InstrumentHeaderLink'
import PortfolioHeaderLink from '@/components/PageComponents/PortfolioHeaderLink'

export default {
    name: 'accounting_fifo',
    components: { 
        FormattedNumber, 
        FormattedDate, 
        TransactionText, 
        ClientHeaderLink, 
        InstrumentHeaderLink, 
        PortfolioHeaderLink, 
    },

    props: {
        clientid: {
            required: true,
        },
        portfolioid: {
            required: true,
        },
        isin: {
            required: true,
        },
        pt: {
            required: true,
        },
        asset: {
            required: true,
        },
    },

    data(){
        return {
            client          : undefined,
            portfolio       : undefined,
            instrument      : undefined,
            loading         : false,
            list            : [],
            accounting_type : undefined,
            portfolio_mode  : 'trade',

            columns: [
                {
                    field : 'isin',
                    title : 'Instrument',
                    width : 110,
                    fixed  : 'left',
                },{
                    title: 'Debit',
                    children: [
                        {
                            field  : 'd_q',
                            title  : 'Volume',
                            width  : 90,
                            slots  : { default: 'col_number' },
                            align  : 'right',
                            params : {
                                format    : {
                                    decimals  : 2,
                                }
                            }
                        },{
                            field : 'd_p',
                            title : 'Transaction Cost',
                            width : 100,
                            slots : { default: 'col_number' },
                            align  : 'right',
                            params : {
                                format    : {
                                    decimals  : 8,
                                }
                            }
                        },{
                            field     : 'd_dt',
                            title     : 'Date',
                            width     : 125,
                            formatter : "formatDateTime"
                        },{
                            field : 'd_oper',
                            title : 'Operation',
                            minWidth : 150,
                            slots : { default: 'col_d_oper' },
                        },{
                            field : 'd_p_clear',
                            title : 'Clear Cost',
                            width : 100,
                            slots : { default: 'col_number' },
                            align  : 'right',
                            params : {
                                format    : {
                                    decimals  : 8,
                                }
                            }
                        },{
                            field : 'd_p_fx',
                            title : 'Cost FX',
                            width : 100,
                            slots : { default: 'col_number' },
                            align  : 'right',
                            params : {
                                format    : {
                                    decimals  : 8,
                                }
                            }
                        },
                    ]
                },{
                    title: 'Credit',
                    children: [
                        {
                            field : 'c_q',
                            title : 'Volume',
                            width : 90,
                            slots : { default: 'col_number' },
                            align  : 'right',
                            params : {
                                format    : {
                                    decimals  : 2,
                                }
                            }
                        },{
                            field : 'c_p',
                            title : 'Transaction Cost',
                            width : 100,
                            slots : { default: 'col_number' },
                            align  : 'right',
                            params : {
                                format    : {
                                    decimals  : 8,
                                }
                            }
                        },{
                            field     : 'c_dt',
                            title     : 'Date',
                            width     : 125,
                            formatter : "formatDateTime"
                        },{
                            field : 'c_oper',
                            title : 'Operation',
                            minWidth : 150,
                            slots : { default: 'col_c_oper' },
                        },{
                            field : 'c_p_clear',
                            title : 'Clear Cost',
                            width : 100,
                            slots : { default: 'col_number' },
                            align  : 'right',
                            params : {
                                format    : {
                                    decimals  : 8,
                                }
                            }
                        },{
                            field : 'c_p_fx',
                            title : 'Cost FX',
                            width : 100,
                            slots : { default: 'col_number' },
                            align  : 'right',
                            params : {
                                format    : {
                                    decimals  : 8,
                                }
                            }
                        },
                    ]
                },{
                    field  : 'row_rpl',
                    title  : 'Row Realized PnL',
                    width  : 100,
                    slots  : { default: 'col_pnl' },
                    params : {
                        format    : {
                            decimals  : 2,
                            zeros     : false,
                            sign      : true,
                            color     : true,
                        }
                    }
                },
                {
                    field  : 'total_rpl',
                    title  : 'Total Realized PnL',
                    width  : 100,
                    slots  : { default: 'col_number' },
                    params : {
                        format    : {
                            decimals  : 2,
                            zeros     : false,
                            sign      : true,
                            color     : true,
                        }
                    }
                },
                {
                    field  : 'row_fx_rpl',
                    title  : 'Row Realized FX PnL',
                    width  : 100,
                    slots  : { default: 'col_pnl' },
                    params : {
                        format    : {
                            decimals  : 2,
                            zeros     : false,
                            sign      : true,
                            color     : true,
                        }
                    }
                },
                {
                    field  : 'total_fx_rpl',
                    title  : 'Total Realized FX PnL',
                    width  : 100,
                    slots  : { default: 'col_number' },
                    params : {
                        format    : {
                            decimals  : 2,
                            zeros     : false,
                            sign      : true,
                            color     : true,
                        }
                    }
                },
                {
                    field  : 'row_price_rpl',
                    title  : 'Row Realized Price PnL',
                    width  : 100,
                    slots  : { default: 'col_pnl' },
                    params : {
                        format    : {
                            decimals  : 2,
                            zeros     : false,
                            sign      : true,
                            color     : true,
                        }
                    }
                },
                {
                    field  : 'total_price_rpl',
                    title  : 'Total Realized Price PnL',
                    width  : 100,
                    slots  : { default: 'col_number' },
                    params : {
                        format    : {
                            decimals  : 2,
                            zeros     : false,
                            sign      : true,
                            color     : true,
                        }
                    }
                },
                {
                    field  : 'row_fees',
                    title  : 'Row Fees',
                    width  : 100,
                    slots  : { default: 'col_number' },
                    params : {
                        format    : {
                            decimals  : 2,
                            zeros     : false,
                            sign      : true,
                            color     : true,
                        }
                    }
                },
                {
                    field  : 'total_fees',
                    title  : 'Total Fees',
                    width  : 100,
                    slots  : { default: 'col_number' },
                    params : {
                        format    : {
                            decimals  : 2,
                            zeros     : false,
                            sign      : true,
                            color     : true,
                        }
                    }
                },
            ]
        }
    },

    computed: {
        table_height(){
            return $(window).height() - 273
        },
    },

    methods: {
        change_mode(){
            this.get_list();
        },
        get_list(){
            this.loading = true
            this.define_accounting_type()
            
            this.$store.dispatch('instrument/getAccountingFifo', {
                acc_type     : this.accounting_type,
                isin         : this.instrument.isin,
                mode         : this.portfolio_mode,
                container    : 'portfolio_id',
                container_id : this.portfolio._id,
                client_id    : this.client._id,
                p_t          : this.pt,
            }).then((response) => {
                this.list = response
                this.loading = false
            })
        },

        define_accounting_type(){
            if (this.asset == 'cash' && this.portfolio.acc_cash == 'fifo')
                this.accounting_type = 'fifo';
            else if (this.asset == 'bond' && this.portfolio.acc_bond == 'fifo')
                this.accounting_type = 'fifo';
            else if (this.asset == 'equity' && this.portfolio.acc_eq == 'fifo')
                this.accounting_type = 'fifo';
            else if (this.asset == 'future' && this.portfolio.acc_future == 'fifo')
                this.accounting_type = 'fifo';
            else if (this.asset == 'option' && this.portfolio.acc_option == 'fifo')
                this.accounting_type = 'fifo';
        },

        header_cell_class_name({ column }){
            if (['d_q','d_p','d_dt','d_oper','d_p_clear','d_p_fx'].includes(column.property)) 
                return 'col-header-debit';
            else if (['c_q','c_p','c_dt','c_oper','c_p_clear','c_p_fx'].includes(column.property)) 
                return 'col-header-credit';
        },

        cell_class_name({ column }){
            if (['d_q','d_p','d_dt','d_oper','d_p_clear','d_p_fx'].includes(column.property)) 
                return 'col-debit';
            else if (['c_q','c_p','c_dt','c_oper','c_p_clear','c_p_fx'].includes(column.property)) 
                return 'col-credit';
        }
    },

    mounted(){
        this.loading = true
        if (this.clientid && this.portfolioid && this.isin) {
            this.$store.dispatch('clients/getClient', this.clientid).then((client_response) => {
                this.client = client_response
                this.$store.dispatch('portfolio/getPortfolio', this.portfolioid).then((portfolio_response) => {
                    this.portfolio = portfolio_response
                    this.$store.dispatch('instrument/getInstrumentShort', {isin: this.isin, asset:this.asset}).then((instrument_response) => {
                        this.instrument = instrument_response
                        this.get_list();
                        this.$emit('tab-title-change', this.instrument.isin + ' accounting in ' + this.portfolio.name)
                    })
                })
            })
        }
    }
}
</script>
